<template>

<app-panel v-on:close="$emit('close')" :loading="is.loading">
		
	<app-panel-title text="Create block" />

	<app-panel-item v-for="type in sortedTypes" :icon="$constants.block.icon[type.value]" :key="type.value" :text="type.text" v-on:click="onTypeClick(type)" />
		
</app-panel>

</template>

<script>

export default {
	
	props: ['types', 'page', 'blocks'],

	data: function() {

		return {
			is: {
				loading: false
			},
			restricted: {
				1: [9,12],
				2: [3,12],
				3: [3,9,12],
				6: [3]
			},
			single: {
				1: [3],
				2: [9],
				3: [],
				6: [3,12]
			}
		}

	},

	created: function() {

		var blockTypes = this.$_.pluck(this.blocks, 'type')

		this.$_.each(this.single[this.page.type], function(id) {

			if (this.$_.contains(blockTypes, id)) this.restricted[this.page.type].push(id)

		}.bind(this))

	},

	computed: {

		sortedTypes: function() {

			var options = []

			this.$_.each(this.types, function(text, value) {

				if (!this.$_.contains(this.restricted[this.page.type], parseInt(value))) {

					options.push({
						value: value,
						text: text
					})
					
				}
			
			}.bind(this))

			return this.$_.sortBy(options, 'text')

		}

	},

	methods: {

		onTypeClick: async function(type) {

			this.is.loading = true

			await this.$api.post(['convention/registration/page', this.$route.params.page, 'block/new'], {
				name: type.text,
				type: type.value
			})

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

</style>
